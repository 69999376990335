import React from 'react'
import Services from './Services'

const ServicesNavbar = () => {
  return (
    <>
    <Services/>
      
    </>
  )
}

export default ServicesNavbar
