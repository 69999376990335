import React from 'react'
import Footer from './Footer'
import './Engineering.css'
// import './Engineering.css'



const Engineering = () => {
  return (
    <>
    <div className="px-2 text-center pb-3">
      <div className='archi-body'>

      <div className='archi-h2-body'>

    <h2 className="display-4  fw-bold  data-text='Engineering' ">Engineering</h2>
      </div>
      </div>
    <div className="col-lg-6 mx-auto">
      <p className="About-p lead mb-3">Design Man Associates offers engineering consultancy services, including most major disciplines of design and testing.

      </p>
    </div>  
  </div>


      <div className="container col-xxl-8 px-4 py-3 ">
    <div className="row flex-lg-row-reverse align-items-center g-5 ">
      
      <div className="col-10 col-sm-8 col-lg-6 mx-auto">
        <img src="./image/SE.jpg" className="d-block mx-lg-auto img-fluid rounded" alt="Structural engineering. Steel bars in different patterns" width="700" height="500" loading="lazy"/>
      </div>

      <div className="col-lg-6">
        <h3 className="display-5 fw-bold text-body-emphasis lh-1 mb-3 Archi-h">Structural Engineering</h3>
        <p className="About-p lead">Structural engineering is an area of specialization of Design Man Associates. 
        </p>
      </div>

    </div>
  </div>

 


  <div className="container px-4 py-1 Archi-areas border-bottom" id="icon-grid">
  <h4 className="container mb-5 fw-bold text-body-emphasis pt-4 text-center px-2 Archi-areas-h">Comprehensive design services are offered in the following specific areas:</h4>

    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 pb-5 Archi-areas-cat">
      <div className="col d-flex align-items-start">
      
        <div>
          <h5 className=" mb-0  ">• Industrial Structures</h5>
          
        </div>
      </div>
      <div className="col d-flex align-items-start">
        
        <div>
          <h5 className=" mb-0 ">• RC Frame Structures</h5>
         
        </div>
      </div>
      <div className="col d-flex align-items-start">
        
        <div>
          <h5 className=" mb-0 ">• Multistoried Commercial Buildings</h5>
          
        </div>
      </div>
      <div className="col d-flex align-items-start">
        
        <div>
          <h5 className="mb-0">• Communication Towers</h5>
         
        </div>
      </div>
      <div className="col d-flex align-items-start">
        
        <div>
          <h5 className="mb-3">• Soil & Water Retaining Structures
</h5>
          
        </div>
      </div>
      <div className="col d-flex align-items-start">
        
        <div>
          <h5 className="mb-0">• Bridges</h5>
         
        </div>
      </div>
      <div className="col d-flex align-items-start">
        
        <div>
          <h5 className="mb-0">• Steel Trusses</h5>
          
        </div>
      </div>
      <div className="col d-flex align-items-start">
        
        <div>
          <h5 className="mb-0">• Pre-engineered Structures</h5>
          
        </div>
      </div>
      <div className="col d-flex align-items-start">
        
        <div>
          <h5 className="mb-0">• Residential Buildings</h5>
          
        </div>
      </div>
      <div className="col d-flex align-items-start">
        
        <div>
          <h5 className="mb-0">• Special Structures</h5>
         
        </div>
      </div>
      
      
      </div>

    <div className="col-lg-12 ">
      <p className="About-p lead mb-3">Capability exists for design with reinforced concrete, pre-stressed concrete, steel, timber and masonry as structural materials. Amongst the references, the American Concrete Institute (ACI), Pre stressing Concrete Institute (PCI), American Institute of Steel Construction  (AISC), Uniform Building Code (UBC), American  Association of State  Highway  and  Transportation  Officials  (AASHTO),  British  Standards  Institute (BSI) standards and codes and other applicable documents are used.
</p>
      <p className="About-p lead mb-3">Complete services are offered including preliminary studies, analysis, design, preparation of engineering and working drawings, development of technical specifications, bills of quantities and engineer’s cost estimates. Studies of existing structures may also be undertaken where structural safety needs to be studied or modification is planned.Using state-of-the-art techniques, full advantage is   taken of the recent developments and progress in materials, construction techniques, analysis and design philosophies and computational methods.
</p>

    </div>

    </div>


  <div className="container col-xxl-8 px-4 py-5 ">
    <div className="row flex-lg-row-reverse align-items-center g-5 py-2">
      
      <div className="col-10 col-sm-8 col-lg-6 mx-auto">
        <img src="./image/MEP.jpg" className="my-5 d-block mx-lg-auto img-fluid rounded" alt="Pipelines in MEP designing" width="700" height="500" loading="lazy"/>
        <img src="./image/MEP2.png" className="my-5 d-block mx-lg-auto img-fluid rounded" alt="3d MEP design of buildings" width="700" height="500" loading="lazy"/>
      </div>

      <div className="col-lg-6">
        <h3 className="display-5 fw-bold text-body-emphasis lh-1 mb-3 Archi-h">MEP Designing</h3>
        <p className="lead About-p">MEP Design Service is an essential part of the building design process that aims to provide a safe and workable habitat for the owners and residents. “Mechanical” involves heating, cooling, and ventilation. The “Electrical” includes power supply grid such as outlets and lighting fixtures of the structure, while the “Plumbing” is focused on water delivery and drainage of wastewater. MEP design should always be developed with the utmost accuracy and attention to detail.
Design Man Associates works as a unified team with dedicated professionals handling your project to ensure its successful completion.  Our engineers with diverse skills and extensive domain expertise create MEPF BIM models and validate these models for constructability, performance, and maintenance of the building.

        </p>
      </div>

    </div>
  </div>


{/* Mechanical Design */}
<div className="px-2 text-center pb-3">
  <h2 className="display-6 fw-bold" data-text='Engineering'>Mechanical Design</h2>
  
  <div className="col-lg-12 mx-auto">
    <p className="About-p lead mb-3">
      The HVAC system is a key mechanical component of every project. The implementation of a correct HVAC system will ensure that the building maintains an adequate temperature and humidity level throughout its occupancy, and ultimately offer maximal comfort to all its users.
    </p>
    <p className="About-p lead mb-3">
      At Design Man Associates, we are proud to have some of the well-versed specialists in the industry as part of our team. Their expertise is what ensures our HVAC systems and other MEP design services are always compliant with building codes. Our HVAC MEP design includes floor plans, air/water riser diagrams, equipment specifications, and construction requirements.
    </p>
  </div>
  
  {/* Responsive Image */}
  <div className="col-lg-5 mx-auto">
    <img 
      src="./image/Mechenical.jpg" 
      className="img-fluid rounded" 
      alt="3d Mechanical Design of buildings" 
    />
  </div>
</div>



  <div className="container px-4 py-1 Archi-areas " id="icon-grid">
  <h4 className="container mb-4 fw-bold text-body-emphasis pt-4 text-center px-2 Archi-areas-h">Our mechanical services include:
</h4>

    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 pb-5 Archi-areas-cat text-center">
      <div className="col d-flex align-items-start">
      
        <div>
          <h5 className=" mb-0  ">• Heating & Cooling system </h5>
          
        </div>
      </div>
      <div className="col d-flex align-items-start">
        
        <div>
          <h5 className=" mb-0 ">• Smoke management system design
</h5>
         
        </div>
      </div>
      <div className="col d-flex align-items-start">
        
        <div>
          <h5 className=" mb-0 ">• Plant room layout design</h5>
          
        </div>
      </div>
      <div className="col d-flex align-items-start">
        
        <div>
          <h5 className="mb-0">• Chilled beam system design</h5>
         
        </div>
      </div>
 
      </div>
      </div>



      <div className="px-2 text-center pb-3">
      

      

      <h2 className="display-6  fw-bold  data-text='Engineering' Archi-h">Electrical Design
</h2>
        
        
      <div className="col-lg-12 mx-auto">
        <p className="About-p lead mb-3">Electrical design is another key element of our MEP services here at Design Man Associates. It includes lighting, receptacles, mechanical power, electrical riser diagrams,
panel board schedules, specifications, and construction details from low to high voltage
Building systems, normal and emergency power.</p>

        {/* Image  */}
        <div className="col-lg-5 mx-auto">
    <img 
      src="./image/E1.jpg" 
      className="img-fluid rounded" 
      alt="Electrical Design in construction planning" 
    />
        </div>

        <p className="About-p lead mb-3">We offer electrical design services for all types of buildings.
A well-designed electrical system is a cornerstone for many other building systems,
including air handlers, water pumps, air conditioners, communication networks,
lighting, and office equipment. Our electrical designers can specify an
installation that delivers the power required by your building while operating safely
and meeting the applicable building codes.

        </p>

          {/* Image  */}
        <div className="col-lg-4 mx-auto">
    <img 
      src="./image/E2.jpg" 
      className="img-fluid rounded" 
      alt="Electrical Engineering" 
    />
        </div>

      </div>  
    </div>
  
    <div className="container px-4 py-1 Archi-areas border-bottom" id="icon-grid">
    <h4 className="container  mb-4 fw-bold text-body-emphasis pt-4 text-center px-2 Archi-areas-h">Our electrical design services include:

  </h4>
  
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 pb-5 Archi-areas-cat">
        <div className="col d-flex align-items-start">
        
          <div>
            <h5 className=" mb-0  ">• Design Basis requirements and Electrical Load calculations.
 </h5>
            
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className=" mb-0 ">• Lighting layout and Lighting calculations.

  </h5>
           
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className=" mb-0 ">• Cable sizing calculation.
</h5>
            
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className="mb-0">• Single Line Diagrams (SLDs).
</h5>
           
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className="mb-0">• Power and Small Power Distribution Layout.
</h5>
           
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className="mb-0">• Security Systems Layout
Building & Energy Management and Control System Design.

</h5>
           
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className="mb-0">• Emergency, UPS and Standby Power Design.
</h5>
           
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className="mb-0">• Photometric Study for Indoor & Outdoor Lighting.
</h5>
           
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className="mb-0">• Data and Telecom Design.
</h5>
           
          </div>
        </div>
        
        
        
        
        </div>
        </div>
  

        <div className="px-2 text-center pb-3">
      

      

      <h2 className="display-6  fw-bold  data-text='Engineering' Archi-h pt-4">Plumbing & Fire Protection Design
</h2>
        
        
      <div className="col-lg-12 mx-auto">
        <p className="About-p lead mb-3">At Design Man Associates we believe in offering full MEP services, especially in the plumbing and fire protection sector. These include the definition of tap sizes by our civil designers, determining the location of incoming utilities while thoroughly implementing effective plumbing solutions to your floor plans. In addition, we are also proud of our residential fire sprinkler system design that saves water and energy, therefore providing an efficient solution to all our customers.


  
        </p>
        
          {/* Image  */}
          <div className="col-lg-4 mx-auto">
    <img 
      src="./image/Plumbing.jpg" 
      className="img-fluid rounded" 
      alt="Plumbing and fire protection design" 
    />
        </div>
       
      </div>  
    </div>
  
    <div className="container px-4 py-1 Archi-areas border-bottom" id="icon-grid">
    <h4 className="container  mb-5 fw-bold text-body-emphasis pt-4 text-center px-2 Archi-areas-h">Our plumbing & fire protection design services include:

  </h4>
  
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 pb-5 Archi-areas-cat">
        <div className="col d-flex align-items-start">
        
          <div>
            <h5 className=" mb-0  ">• Sizing & Selection of Calorifiers, Pumps, Water Storage Tanks.

 </h5>
            
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className=" mb-0 ">• Pipe sizing for water services.


  </h5>
           
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className=" mb-0 ">• Water services layout design.
</h5>
            
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className="mb-0">• Soil & Waste system management design.

</h5>
           
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className="mb-0">• Medical Gas & Vacuum Piping Design.

</h5>
           
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className="mb-0">• Fuel Gas Piping Design.


</h5>
           
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className="mb-0">• Fire water supply system layout design. 

</h5>
           
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className="mb-0">• Smoke detection, sprinkler and gas suppression system design.

</h5>
           
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className="mb-0">• Design & preparation of Sprinkler/Hydrant/Water Spray/Foam systems.
</h5>
           
          </div>
        </div>
        
        
        
        
        </div>
        </div>




  <div className="container col-xxl-8 px-4 py-5 ">
    <div className="row flex-lg-row-reverse align-items-center g-5 py-1">
      
      <div className="col-10 col-sm-8 col-lg-6 mx-auto">
        <img src="./image/SoilT.png" className="d-block mx-lg-auto img-fluid rounded" alt="Soil and building testing with construction equipments" width="700" height="500" loading="lazy"/>


      </div>

      <div className="col-lg-6">
        <h3 className="display-5 fw-bold text-body-emphasis lh-1 mb-3 Archi-h">Soil & Building Testing</h3>
        <p className="lead About-p">Design Man Associates is committed to offer quality, value addition, high tech legally compliant  work and diverse range of  services in the areas of  material  testing and construction analysis for  building and roads,  Geo- technical investigation services, destructive and non- destructive testing, evaluation and repair  of  fire damaged buildings and solutions.  Ferro scanning of concrete structures and failure analysis.

        </p>
      </div>

    </div>
  </div>
  <div className="container px-4 py-1 Archi-areas border-bottom" id="icon-grid">
    <h4 className="container  mb-5 fw-bold text-body-emphasis  text-center px-2 Archi-areas-h">Detailed testing services are offered in the following:

  </h4>
  
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 pb-5 Archi-areas-cat">
        <div className="col d-flex align-items-start">
        
          <div>
            <h5 className=" mb-0  ">• Geo-technical Investigation 

 </h5>
            
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className=" mb-0 ">• Concrete Core Testing


  </h5>
           
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className=" mb-0 ">• Rebound Hammer Testing

</h5>
            
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className="mb-0">• Ferro scanning of  Concrete Structures

</h5>
           
          </div>
        </div>
        <div className="col d-flex align-items-start">
          
          <div>
            <h5 className="mb-0">• Analysis of Existing Structures

</h5>
           
          </div>
        </div>
        
        
        
        
        
        </div>
        </div>


            <Footer/>

  </>
  )
}

export default Engineering
